<template>
    <div>
        <banner></banner>
        <div class="wrap w-1300">
            <!-- <div class="mokuai-wrap">
                <div class="gengduo">
                    <div class="center-text">
                        <p></p>
                        <span>供应端</span>
                    </div>
                    <div class="more">更多</div>
                </div>                
                <div class="mokuai-list">
                    <div class="mokuai-item">
                        <div class="mokuai-text">联合国采购</div>
                        <div class="mokuai-p" @click="goTo('supply_details')">
                            <p>01</p>
                            <span>专注于服务出口企业，打造N+122222</span>
                        </div>
                        <div class="mokuai-p">
                            <p>02</p>
                            <span>专注于服务出口企业，打造N+122222</span>
                        </div>
                        <div class="mokuai-p">
                            <p>03</p>
                            <span>专注于服务出口企业，打造N+122222</span>
                        </div>
                        <div class="mokuai-p">
                            <p>04</p>
                            <span>专注于服务出口企业，打造N+122222</span>
                        </div>
                        <div class="more" @click="goTo('supply')">查看更多 <i class="el-icon-arrow-right"></i></div>
                    </div>
                    <div class="mokuai-item">
                        <div class="mokuai-text">联合国采购</div>
                        <div class="mokuai-p">
                            <p>01</p>
                            <span>专注于服务出口企业，打造N+122222</span>
                        </div>
                        <div class="mokuai-p">
                            <p>02</p>
                            <span>专注于服务出口企业，打造N+122222</span>
                        </div>
                        <div class="mokuai-p">
                            <p>03</p>
                            <span>专注于服务出口企业，打造N+122222</span>
                        </div>
                        <div class="mokuai-p">
                            <p>04</p>
                            <span>专注于服务出口企业，打造N+122222</span>
                        </div>
                        <div class="more">查看更多 <i class="el-icon-arrow-right"></i></div>
                    </div>
                    <div class="mokuai-item">
                        <div class="mokuai-text">联合国采购</div>
                        <div class="mokuai-p">
                            <p>01</p>
                            <span>专注于服务出口企业，打造N+122222</span>
                        </div>
                        <div class="mokuai-p">
                            <p>02</p>
                            <span>专注于服务出口企业，打造N+122222</span>
                        </div>
                        <div class="mokuai-p">
                            <p>03</p>
                            <span>专注于服务出口企业，打造N+122222</span>
                        </div>
                        <div class="mokuai-p">
                            <p>04</p>
                            <span>专注于服务出口企业，打造N+122222</span>
                        </div>
                        <div class="more">查看更多 <i class="el-icon-arrow-right"></i></div>
                    </div>
                    <div class="mokuai-item">
                        <div class="mokuai-text">联合国采购</div>
                        <div class="mokuai-p">
                            <p>01</p>
                            <span>专注于服务出口企业，打造N+122222</span>
                        </div>
                        <div class="mokuai-p">
                            <p>02</p>
                            <span>专注于服务出口企业，打造N+122222</span>
                        </div>
                        <div class="mokuai-p">
                            <p>03</p>
                            <span>专注于服务出口企业，打造N+122222</span>
                        </div>
                        <div class="mokuai-p">
                            <p>04</p>
                            <span>专注于服务出口企业，打造N+122222</span>
                        </div>
                        <div class="more">查看更多 <i class="el-icon-arrow-right"></i></div>
                    </div>
                </div>
            </div> -->
            
            <!-- <div class="gengduo">
                <div class="center-text">
                    <p></p>
                    <span>易货端</span>
                </div>
                <div class="more">更多</div>
            </div>
            <div class="yihuo-wrap">
                <div class="yihuo-img"><img src="../../../assets/images/yhd.png" alt=""></div>
                <div class="yihuo">
                    <div class="yihuo-item">
                        <div class="yihuo-time">
                            <p>15</p>
                            <p>2021-05</p>
                        </div>
                        <div class="yihuo-p">专注于服务出口企业，打造N+1专注于服务出口企业，打造N+1专注于服务出口企业，打造N+1打造N+1专注于服务出口企业企业企业</div>
                    </div>
                    <div class="yihuo-item">
                        <div class="yihuo-time">
                            <p>15</p>
                            <p>2021-05</p>
                        </div>
                        <div class="yihuo-p">专注于服务出口企业，打造N+1专注于服务出口企业，打造N+1专注于服务出口企业，打造N+1打造N+1专注于服务出口企业企业企业</div>
                    </div>
                    <div class="yihuo-item">
                        <div class="yihuo-time">
                            <p>15</p>
                            <p>2021-05</p>
                        </div>
                        <div class="yihuo-p">专注于服务出口企业，打造N+1专注于服务出口企业，打造N+1专注于服务出口企业，打造N+1打造N+1专注于服务出口企业企业企业</div>
                    </div>
                    <div class="yihuo-item">
                        <div class="yihuo-time">
                            <p>15</p>
                            <p>2021-05</p>
                        </div>
                        <div class="yihuo-p">专注于服务出口企业，打造N+1专注于服务出口企业，打造N+1专注于服务出口企业，打造N+1打造N+1专注于服务出口企业企业企业</div>
                    </div>
                </div>
            </div> -->

            <div v-for="(item,index) in listData" :key="index">
                <div class="gengduo">
                    <div class="center-text">
                        <p></p>
                        <span>{{item.name}}</span>
                    </div>
                    <div class="more"  @click="goTo('supply',{id:item.id})">更多</div>
                </div>
                <div class="xuqiu-wrap">
                    <div class="xuqiu" v-for="(item1,index1) in tuijianliebiao[item.id]" :key="index1">
                        <div class="xuqiu-text">{{item1.name}}</div>
                        <div class="xuqiu-p" v-html="delLabel(item1.content)"></div>
                        <div class="xuqiu-bt"  @click="goTo('supply_details',{id:item1.id})">查看详情</div>
                    </div>
                </div>

            </div>
            



            <div class="gengduo">
                <div class="center-text">
                    <p></p>
                    <span>供求对接分类</span>
                </div>
                <div class="more" @click="goTo('supply_state')">更多国家区域</div>
            </div>
            <div class="shop-wrap">
                <div class="shop-left">
                    <div class="pinpai-list-text">供求对接分类<p></p></div>
                    <div class="pinpai-item-wrap">                        
                        <div class="pinpai-item" v-for="(item,index) in stateList" :key="index" @click="goTab(item.id)">
                            <p :class="{active:state_id==item.id}">{{item.name}}</p>
                            <img src="../../../assets/images/right.png" alt="">
                        </div>                        
                    </div>
                </div>
                <div class="shop-right">
                    <div class="xuqiu" v-for="(item,index) in guojiaShop" :key="index">
                        <div class="xuqiu-text">{{item.name}}</div>
                        <div class="xuqiu-p" v-html="delLabel(item.content)"></div>
                        <div class="xuqiu-bt"  @click="goTo('supply_details',{id:item.id})">查看详情</div>
                    </div>                    
                </div>

            </div>
            
        </div>

    </div>
</template>

<script>
    import banner from '../../../components/banner.vue';
    import { getplates ,getplatexts,getcountrys} from "../../../api/index"
    export default {
        data() {
            return {
                listData:[],
                tuijianliebiao:[],
                stateList:[],
                state_id:'',
                guojiaShop:[]
                
            }
        },
        components:{
            banner
        },
        created() {
            this.getplates()
            this.getcountrys()
            
        },
        methods: {
            // 跳转页面
            goTo(url,query) {
                this.$router.push({ 
                    path: url ,
                    query
                });
            },
            // 去除富文本所有标签
            delLabel(input){
                if (input!=null) {
                    return input.replace(/<\/?[^>]*>/g, ""); 
                }
            },
            goTab(id){
                this.state_id=id
                this.getplatextsGj()
            },
            // 推荐分类
            getplates(){
                getplates({
                    p_id:9,
                    recom:1
                }).then((res)=>{
                    // console.log(res)
                    if (res.code==200) {
                        this.listData=res.data.slice(0,3)
                        for (let i = 0; i < this.listData.length; i++) {
                            this.getplatexts(this.listData[i].id)                            
                        } 
                    }
                })
            },
            //推荐分类下面的列表
            getplatexts(plate_id){
                getplatexts({
                    plate_id
                }).then((res)=>{
                    // console.log(res)
                    if (res.code==200) {
                        this.tuijianliebiao[plate_id]=res.data.data.slice(0,4);
                        this.$set(this.tuijianliebiao,plate_id,this.tuijianliebiao[plate_id])
                        // console.log(this.liebiaoList)
                    }

                })

            },
            // 获取国家
            getcountrys(){
                getcountrys({
                    
                }).then((res)=>{
                    // console.log(res)
                    if (res.code==200) {
                        this.stateList=res.data
                        if (res.data.length>0) {
                            this.state_id=res.data[0].id
                            this.getplatextsGj()
                        }
                    }
                })
            },
            //国家分类下面的列表
            getplatextsGj(){
                getplatexts({
                    plate_id:9,
                    coun_id:this.state_id,
                    per_page:9
                }).then((res)=>{
                    console.log(res)
                    if (res.code==200) {
                        this.guojiaShop=res.data.data
                    }

                })

            },
            
        },
        
    }
</script>

<style lang="less" scoped>
.gengduo{
    margin-top:45px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    .center-text{margin-top: 0 ;}
    .more{font-size: 16px; color: #FE6D00;text-decoration: underline;}
}
.wrap{
    margin: auto;
    .mokuai-wrap{
        margin: auto;
        .mokuai-list{
            margin:45px;
            display: flex;
            .mokuai-item{
                width: 23%;
                margin-right: 2.6666%;
                border: 1px solid #E9E9E9;
                box-sizing: border-box;
                .mokuai-text{padding: 15px;border-bottom: 1px solid #e9e9e9;}
                .mokuai-p{
                    display: flex;
                    align-items: center;
                    color: #666666;
                    font-size: 15px;
                    margin: 20px 15px;    
                    cursor: pointer;                
                    p{
                        background: #FE6D00;
                        color: #fff;
                        padding: 4px 6px;
                        margin-right: 5px;
                    }
                    span{
                        overflow: hidden;
                        white-space: nowrap;
                        text-overflow: ellipsis;

                    }
                }
                .more{
                    padding: 20px;
                    padding-top: 10px;
                    text-align: right;
                    color: #929191;
                    font-size: 14px;
                    cursor: pointer;
                }
                &:last-of-type{margin-right: 0;}
            }
        }
    }

    .xuqiu-wrap{
        display: flex;
        margin-top: 45px;
        .xuqiu{
            width: 23%;
            margin-right: 2.6666%;
            background: #F7F7F7;
            box-shadow: 0px 3px 9px 0px rgba(0, 0, 0, 0.16);
            border: 1px solid #E8E8E8;
            border-top: 3px solid #0071DB;
            &:nth-child(4n+0){margin-right: 0;}
            .xuqiu-text{
                margin: 35px 0 20px 0;
                text-align: center;
                font-size: 22px;
                font-weight: 300;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 1;
                -webkit-box-orient: vertical;
            }
            .xuqiu-p{
                height: 72px;
                padding: 0 18px;
                color: #666666;
                line-height: 24px;
                font-size: 16px;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 3;
                -webkit-box-orient: vertical;
            }
            .xuqiu-bt{
                width: 125px;
                height: 35px;
                line-height: 35px;
                text-align: center;
                border: 2px solid #FE6D00;
                background: #fff;
                font-size: 16px;
                margin: 35px 18px 21px 18px;
                cursor: pointer;
            }
        }
    }

    .yihuo-wrap{
        display: flex;
        margin-top: 45px;
        .yihuo-img{
            height: 430px;
            margin-right: 20px;
            img{height: 100%;}
        }
        .yihuo{
            flex: 1;
            height: 430px;
            .yihuo-item{
                display: flex;
                height: 95px;
                margin-bottom: 16px;
                align-items: center;
                background: #F2F4F6;
                color: #666;
                border-radius: 5px;
                .yihuo-time{
                    padding: 0 15px;                    
                    text-align: center;
                    flex-shrink: 0;
                    font-size: 18px;
                    padding-bottom: 5px;
                    p:nth-child(1){font-size: 36px;margin-bottom: 5px;}
                }
                .yihuo-p{
                    padding: 4px 20px;
                    border-left:1px solid #333;
                    line-height: 26px;
                    font-size: 16px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;

                }
                &:hover{
                    background: #0071DB;
                    color: #fff;
                    .yihuo-p{
                        border-left: 1px solid #fff;
                    }

                }
            }
        }
    }


    .shop-wrap{
        margin-top: 45px;
        margin-bottom: 140px;
        display: flex;
        .shop-left{
            width: 20%;
            .pinpai-list-text{position: relative;
                height: 43px;line-height: 43px; background: #0071DB;padding-left: 45px;font-size: 18px;font-weight: bold;color: #fff;
                p{width: 11px;height: 11px;background: #fff;border-radius: 50%;position: absolute;top: 16px;left: 22px;}    
            }
            .pinpai-item-wrap{width: 100%;background: #EEEFF0;padding-bottom: 28px;}
            .pinpai-item{padding: 28px 37px 0 45px;display: flex;justify-content: space-between;align-items: center;cursor: pointer;
                p{color: #333333;font-size: 18px;}
                img{width: 8px;}
                &:hover{
                    p{color: #0071DB;}
                }
            }
            .active{color: #0071DB !important;}
        }
        .shop-right{
            width: 80%;
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            align-content: flex-start;
            .xuqiu{
                width: 30%;
                margin-left: 3.33333%;
                background: #F7F7F7;
                box-shadow: 0px 3px 9px 0px rgba(0, 0, 0, 0.16);
                border: 1px solid #E8E8E8;
                border-top: 3px solid #0071DB;
                box-sizing: border-box;
                margin-bottom: 30px;
                .xuqiu-text{
                    padding: 35px 0 20px 0;
                    text-align: center;
                    font-size: 22px;
                    font-weight: 300;
                }
                .xuqiu-p{
                    height: 72px;
                    padding: 0 18px;
                    color: #666666;
                    line-height: 24px;
                    font-size: 16px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    -webkit-line-clamp: 3;
                    -webkit-box-orient: vertical;
                }
                .xuqiu-bt{
                    width: 125px;
                    height: 35px;
                    line-height: 35px;
                    text-align: center;
                    border: 2px solid #FE6D00;
                    background: #fff;
                    font-size: 16px;
                    margin: 35px 18px 21px 18px;
                    cursor: pointer;
                }
            }
            

        }
    }

}

</style>